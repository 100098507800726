import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-youtube';

import './looper-global.sass';
import { InitPlayer } from './elemets';
import { defaultOptions } from './defaultOptions';
import { defaultFormatter } from './utils';
import storage from '@/store/localStore';

export const baseLooperMixin = {
  data() {
    return {
      player: null,
      playerOptions: defaultOptions(this.url),
      loops: [],
      loopFocused: undefined,
      combinedLoops: [],
    };
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    externalLoops: {
      type: Object,
    },
    bus: {
      type: Object,
      required: true,
    },
    onSaveLoops: {
      type: Function,
      required: true,
    },
  },
  computed: {
    currentLoop() {
      if (this.combinedLoops.length) {
        const bigLoop = { _id: null, start: null, end: null };
        this.loops.forEach(el => {
          if (el._id === this.combinedLoops[0]) {
            bigLoop.start = el.start;
          }
          if (el._id === this.combinedLoops[this.combinedLoops.length - 1]) {
            bigLoop.end = el.end;
          }
        });
        this.updateOffsetCombined(bigLoop.start);
        this.updateWidthForCombined(bigLoop.start, bigLoop.end);
        return bigLoop;
      }
      return this.loops.find(el => el._id === this.loopFocused);
    },
  },
  watch: {
    loopFocused() {
      if (!this.currentLoop) return;
      if (!this.combinedLoops.includes(this.loopFocused)) {
        this.combinedLoops = [];
      }
      this.updateLoopOffset();
      this.updateSeekBarBorders();
      if (this.currentLoop.start) {
        this.player.currentTime(this.currentLoop.start);
        this.toggleStartClass(true);
      } else {
        this.toggleStartClass(false);
      }
      if (this.currentLoop.end) {
        this.updateLoopWidth(this.currentLoop.end);
        this.toggleEndClass(true);
      } else {
        this.toggleEndClass(false);
      }
    },
    combinedLoops() {
      if (!this.combinedLoops.length) return;
    },
  },
  methods: {
    formatTime(val) {
      return defaultFormatter(val);
    },
    loopHasStart() {
      return this.currentLoop && typeof this.currentLoop.start === 'number';
    },
    loopHasEnd() {
      return this.currentLoop && typeof this.currentLoop.end === 'number';
    },
    addLoop() {
      const lastId = this.loops.length
        ? this.loops[this.loops.length - 1]._id
        : 0;
      this.loops.push({ _id: lastId + 1, start: null, end: null });
      this.loopFocused = lastId + 1;
      if (this.loops.length <= 1) return;
      this.toggleStartClass(false);
      this.toggleEndClass(false);
      this.updateLoopOffset();
      this.updateLoopWidth();
      this.updateSeekBarBorders();
    },
    getControlBar() {
      return this.player.getChild('ControlBar');
    },
    getSeekBar() {
      return this.getControlBar()
        .getChild('ProgressControl')
        .getChild('CustomSeek');
    },
    toggleStartClass(manualToggle) {
      const startBtn = this.getControlBar()
        .getChild('CustomWrapper')
        .getChild('StartButton');
      if (typeof manualToggle === 'undefined') {
        startBtn.toggleClass('start');
        return;
      } else if (manualToggle) {
        startBtn.addClass('start');
      } else {
        startBtn.removeClass('start');
      }
    },
    toggleEndClass(manualToggle) {
      const endBtn = this.getControlBar()
        .getChild('CustomWrapper')
        .getChild('EndButton');
      if (typeof manualToggle === 'undefined') {
        endBtn.toggleClass('end');
        return;
      } else if (manualToggle) {
        endBtn.addClass('end');
      } else {
        endBtn.removeClass('end');
      }
    },
    saveLoopToStorage() {
      this.onSaveLoops(this.url, {
        loops: this.loops,
        active: this.currentLoop ? this.currentLoop._id : null,
        duration: this.player.duration(),
      });
    },
    clearLoopFromStorage() {
      storage.loopsStorage.setItem(this.url, []);
    },
    updateCombinedLoop(loopId) {
      if (this.combinedLoops.includes(loopId)) {
        this.combinedLoops = this.combinedLoops
          .filter(el => el !== loopId)
          .sort();
      } else {
        if (this.combinedLoops.length && this.checkCombinedLoopHasGap(loopId)) {
          this.combinedLoops = [loopId];
          this.loopFocused = loopId;
        } else {
          if (!this.combinedLoops.length && this.currentLoop._id !== loopId) {
            this.loopFocused = loopId;
          }
          this.combinedLoops.push(loopId);
          this.combinedLoops.sort();
        }
      }
    },
    checkCombinedLoopHasGap(addedId) {
      const { start, end } = this.loops.find(el => el._id === addedId);
      if (typeof start === 'undefined' || typeof end === 'undefined')
        return true;
      const firstCombined = this.loops.find(
        el => el._id === this.combinedLoops[0]
      );
      const lastCombined = this.loops.find(
        el => el._id === this.combinedLoops[this.combinedLoops.length - 1]
      );

      const startGap = start - lastCombined.end;
      const endGap = firstCombined.start - end;
      return startGap * 1000 > 2000 || endGap * 1000 > 2000;
    },
    updateOffsetCombined(start) {
      const duration = this.player.duration();
      const seek = this.getSeekBar();
      const leftOffset = start / duration;
      seek.el().style.left = `calc(${(leftOffset * 100).toFixed(2)}% + 0px)`;
    },
    updateLoopOffset(customDuration) {
      const duration =
        typeof customDuration === 'undefined'
          ? this.player.duration()
          : customDuration;
      const seek = this.getSeekBar();
      if (!this.currentLoop) {
        seek.el().style.left = `calc(${0}% + 0px)`;
        return;
      }
      const leftOffset = this.currentLoop.start / duration;
      seek.el().style.left = `calc(${(leftOffset * 100).toFixed(2)}% + 0px)`;
    },
    updateWidthForCombined(start, end) {
      const seek = this.getSeekBar();
      const duration = this.player.duration();

      const baseWidth = (duration - start) / duration;

      const loopCurrent = end - start;
      const loopDuration = duration - start;
      const seekWidth = (loopCurrent / loopDuration) * baseWidth;
      seek.el().style.width = `${(seekWidth * 100).toFixed(2)}%`;
    },
    updateLoopWidth(currentEnd, customDuration) {
      const seek = this.getSeekBar();
      const duration =
        typeof customDuration === 'undefined'
          ? this.player.duration()
          : customDuration;

      if (!this.currentLoop) {
        seek.el().style.left = `calc(${0}% + 0px)`;
        return;
      }
      const baseWidth = (duration - this.currentLoop.start) / duration;

      const loopCurrent = currentEnd - this.currentLoop.start;
      const loopDuration = duration - this.currentLoop.start;
      const seekWidth = (loopCurrent / loopDuration) * baseWidth;
      seek.el().style.width = `${(seekWidth * 100).toFixed(2)}%`;
    },
    updateSeekBarBorders() {
      const seek = this.getSeekBar();
      if (
        !this.currentLoop ||
        (!this.currentLoop.start && !this.currentLoop.end)
      ) {
        seek.el().classList.remove('borders');
      } else {
        seek.el().classList.add('borders');
      }
    },
    rewind() {
      if (this.currentLoop && this.currentLoop.start) {
        this.player.currentTime(this.currentLoop.start);
      } else {
        this.player.currentTime(0);
      }
    },
    handleStart() {
      this.toggleStartClass();
      // create first loop
      if (!this.currentLoop) {
        this.addLoop();
      }
      if (this.loopHasStart()) {
        this.currentLoop.start = null;
        this.updateLoopOffset();
        this.updateLoopWidth(this.currentLoop.end || 0);
        this.updateSeekBarBorders();
        // delete loop
        if (!this.currentLoop.end) {
          this.deleteLoop(this.currentLoop._id);
        } else {
          this.saveLoopToStorage();
        }
        return;
      }
      this.currentLoop.start = this.player.currentTime();
      this.loopHasEnd() && this.saveLoopToStorage();
      // set left offset from parent
      this.updateLoopOffset();
      if (this.currentLoop.end) {
        this.updateLoopWidth(this.currentLoop.end);
      }
      // listen to time updates
      this.listenToTimeUpdates();
      this.updateSeekBarBorders();
    },
    handleEnd() {
      this.toggleEndClass();
      // create first loop
      if (!this.currentLoop) {
        this.addLoop();
      }
      if (this.currentLoop.end) {
        this.currentLoop.end = null;
        this.updateSeekBarBorders();
        if (!this.loopHasStart()) {
          this.updateLoopWidth(0);
          this.deleteLoop(this.currentLoop._id);
        }
        return;
      }
      const end = this.player.currentTime();
      // start loop again
      this.currentLoop.end = end;
      // save to storage
      this.saveLoopToStorage();

      this.player.currentTime(this.currentLoop.start);
      // set static bar width
      this.updateLoopWidth(this.currentLoop.end);
      this.updateSeekBarBorders();
    },
    seekPrevious() {
      if (!this.currentLoop || !this.currentLoop.start) return;
      this.currentLoop.start = this.currentLoop.start - 0.5;
      this.player.currentTime(this.currentLoop.start);
      this.updateLoopOffset();
      if (this.currentLoop.end) {
        this.updateLoopWidth(this.currentLoop.end);
      } else {
        this.updateLoopWidth(this.player.currentTime());
      }
      this.saveLoopToStorage();
    },
    seekNext() {
      if (!this.currentLoop || !this.currentLoop.end) return;
      this.currentLoop.end = this.currentLoop.end + 0.5;
      this.updateLoopWidth(this.currentLoop.end);
      this.saveLoopToStorage();
    },
    listenToTimeUpdates() {
      this.player.on('timeupdate', this.onPlayerTimeUpdate);
    },
    onPlayerTimeUpdate() {
      if (!this.currentLoop) {
        this.player.off('timeupdate', this.onPlayerTimeUpdate);
        return;
      }
      // fill in loop bar
      requestAnimationFrame(() => {
        if (!this.currentLoop.end && this.currentLoop.start) {
          this.updateLoopWidth(this.player.currentTime());
        }
        // rerun on loop end
        if (
          this.currentLoop.end &&
          this.player.currentTime() >= this.currentLoop.end
        ) {
          this.player.off('timeupdate', this.onPlayerTimeUpdate);
          this.player.pause();

          // Re-bind to timeupdate next time the video plays
          this.player.one('play', () => {
            this.listenToTimeUpdates();
          });
          this.player.play();
          this.player.currentTime(this.currentLoop.start);
        }
      });
    },
    loadExternalLoops() {
      const { loops, duration, active } = JSON.parse(
        JSON.stringify(this.externalLoops)
      );
      if (!loops.length) return;
      this.loops = loops;
      this.loopFocused = active || this.loops[0]._id;
      this.updateLoopOffset(duration);
      this.updateSeekBarBorders();
      this.updateLoopWidth(this.currentLoop.end || 0, duration);
      typeof this.currentLoop.start === 'number' && this.toggleStartClass();
      this.toggleEndClass();
      this.player.play();
      this.player.muted(true);
      setTimeout(() => {
        this.player.currentTime(this.currentLoop.start);
        this.player.pause();
        this.player.muted(false);
      }, 1000);
      this.listenToTimeUpdates();
    },
  },
  mounted() {
    InitPlayer({
      onStart: this.handleStart,
      onEnd: this.handleEnd,
      onRewind: this.rewind,
      onSeekPrev: this.seekPrevious,
      onSeekNext: this.seekNext,
    });

    this.player = videojs(this.$refs.videoPlayer, this.playerOptions);

    const controlBar = this.getControlBar();
    const bigBtn = this.player.getChild('BigPlayButton');
    bigBtn.hide();
    controlBar
      .getChild('ProgressControl')
      .getChild('SeekBar')
      .getChild('LoadProgressBar')
      .hide();
    controlBar.getChild('ProgressControl').addChild('CustomSeek');
    this.player.ready(() => {
      if (this.externalLoops) {
        this.loadExternalLoops();
      }
    });
    this.bus.$on('clearLoop', () => {
      this.deleteLoop(this.currentLoop._id);
    });
  },
};
