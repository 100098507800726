<template>
  <v-card class="looper-card mx-auto">
    <div class="loops-info">
      <v-list color="secondary" dark>
        <v-list-item-group v-model="loopFocused" mandatory>
          <v-list-item
            two-line
            v-for="(item, i) in loops"
            :key="item._id"
            :value="item._id"
            class="list-item"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                <span class="loop-text">Loop {{ i + 1 }}</span>
                <v-btn
                  x-small
                  :outlined="!combinedLoops.includes(item._id)"
                  :color="
                    !combinedLoops.includes(item._id) ? 'white' : 'primary'
                  "
                  @click.stop
                  @mousedown.stop
                  @touchstart.stop
                  @click="() => updateCombinedLoop(item._id)"
                  class="btn-combine"
                  >{{
                    !combinedLoops.includes(item._id) ? 'combine' : 'combined'
                  }}</v-btn
                >
              </v-list-item-subtitle>
              <v-list-item-title class="loop-item-title">
                <div>
                  <span>{{ formatTime(item.start) }}</span> -
                  <span>{{ formatTime(item.end) }}</span>
                </div>
                <v-btn small icon @click="() => deleteLoop(item._id)">
                  <v-icon small>mdi-delete-forever</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-btn
            class="d-flex mx-auto mt-2"
            x-small
            fab
            dark
            color="primary"
            @click="() => addLoop()"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-group>
      </v-list>
    </div>
    <video
      id="my-player"
      ref="videoPlayer"
      class="vjs-looper vjs-fluid video-js player"
      preload
      loop
      controls
    ></video>

    <div class="looper-dummy">
      <!-- <div class="pro-btn-wrapper">
        <v-btn width="100" color="primary">Upgrade</v-btn>
      </div>-->
    </div>
  </v-card>
</template>

<script>
import { baseLooperMixin } from './basePlayerMixin';

export default {
  mixins: [baseLooperMixin],
  methods: {
    deleteLoop(id) {
      if (id === this.loopFocused) {
        if (this.loopHasStart()) this.handleStart();
        if (this.loopHasEnd()) this.handleEnd();
      }
      let loopToDelete = typeof id !== 'undefined' ? id : this.loopFocused;
      this.loops = this.loops.filter(el => el._id !== loopToDelete);
      this.loopFocused = this.loops.length
        ? this.loops[this.loops.length - 1]._id
        : undefined;
      this.saveLoopToStorage();
    },
  },
};
</script>

<style lang="sass" scoped>
.looper-card
  display: grid
  grid-template-columns: 150px auto
  background-color: $looper-background !important
  @include mq("tablet", "max")
    grid-template-columns: auto
    grid-template-rows: 200px auto 85px
  .loops-info
    background-color: $looper-background
    overflow-y: auto
    @include mq("tablet", "min")
      max-height: 450px
    .list-item
      min-height: auto
      .v-list-item__content
        padding: 0
        .v-list-item__subtitle
          font-size: 0.9rem
          line-height: 0.9rem
          margin: 0
          padding-top: 2px
          display: flex
          justify-content: space-between
          .loop-text
            @include mq("tablet", "min")
              margin-right: 5px
              font-size: .7rem
          .btn-combine
            height: 12px
            padding: 0 4px
            @include mq("tablet", "min")
              font-size: .525rem
        .loop-item-title
          font-size: 0.8rem
          line-height: 0.8rem
          +flex-ctr()
          justify-content: space-between
          >div
            text-overflow: ellipsis
            overflow-x: hidden
            overflow-y: hidden
  .looper-dummy
    background-color: $looper-background
    height: 85px
    +flex-ctr()
    @include mq("tablet", "max")
      margin-top: 85px
    .pro-btn-wrapper
      +flex-ctr()
</style>
