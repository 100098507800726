export const defaultOptions = url => ({
  sources: [
    {
      type: 'video/youtube',
      src: url,
    },
  ],
  controlBar: {
    children: [
      'PlayToggle',
      { name: 'VolumePanel', inline: false },
      'CurrentTimeDisplay',
      'ProgressControl',
      'RemainingTimeDisplay',
      'PlaybackRateMenuButton',
      'FullscreenToggle',
      {
        name: 'CustomWrapper',
        children: [
          'RewindButton',
          'SeekPrevButton',
          'StartButton',
          'EndButton',
          'SeekNextButton',
        ],
      },
    ],
  },
  inactivityTimeout: 0,
  playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5],
  youtube: {
    ytControls: 1,
  },
});
