import videojs from 'video.js';

function pressEffect(context) {
  context.el().style.transform = 'scale(1.02)';
  setTimeout(() => {
    context.el().style.transform = 'scale(1)';
  }, 150);
}

export function InitPlayer(methods) {
  class CustomSeek extends videojs.getComponent('Component') {
    constructor(player, options = {}) {
      super(player, options);
    }
    createEl() {
      const myEl = super.createEl('div', {
        className: 'custom-seek',
      });
      return myEl;
    }
  }
  class StartButton extends videojs.getComponent('Button') {
    constructor(player, options = {}) {
      super(player, options);
      this.addClass('vjs-custom-button');
    }
    createEl() {
      const myEl = super.createEl('button', {
        className: 'start-btn',
      });
      myEl.innerText = '[start';
      return myEl;
    }
    handleClick() {
      methods.onStart();
    }
  }
  class RewindButton extends videojs.getComponent('Button') {
    constructor(player, options = {}) {
      super(player, options);
      this.addClass('vjs-custom-button');
    }
    createEl() {
      const myEl = super.createEl('button', {
        className: 'rewind-btn',
      });
      return myEl;
    }
    handleClick() {
      pressEffect(this);
      methods.onRewind();
    }
  }
  class SeekPrevButton extends videojs.getComponent('Button') {
    constructor(player, options = {}) {
      super(player, options);
      this.addClass('vjs-custom-button');
      this.addClass('seek-start-btn');
      this.removeClass('vjs-control');
      this.removeClass('vjs-button');
    }
    handleClick() {
      pressEffect(this);
      methods.onSeekPrev();
    }
  }
  class SeekNextButton extends videojs.getComponent('Button') {
    constructor(player, options = {}) {
      super(player, options);
      this.addClass('vjs-custom-button');
    }
    createEl() {
      const myEl = super.createEl('button', {
        className: 'seek-end-btn',
      });
      return myEl;
    }
    handleClick() {
      pressEffect(this);
      methods.onSeekNext();
    }
  }
  class EndButton extends videojs.getComponent('Button') {
    constructor(player, options = {}) {
      super(player, options);
      this.addClass('vjs-custom-button');
    }
    createEl() {
      const myEl = super.createEl('button', {
        className: 'end-btn',
      });
      myEl.innerText = 'end]';
      return myEl;
    }
    handleClick() {
      methods.onEnd();
    }
  }
  class CustomWrapper extends videojs.getComponent('Component') {
    constructor(player, options = {}) {
      super(player, options);
      this.addClass('vjs-my-wrapper');
    }
    createEl() {
      const myEl = super.createEl('div', {
        className: 'loop-wrapper',
      });
      return myEl;
    }
  }
  const comp = videojs.getComponent('Component');
  comp.registerComponent('CustomWrapper', CustomWrapper);
  comp.registerComponent('CustomSeek', CustomSeek);
  comp.registerComponent('StartButton', StartButton);
  comp.registerComponent('EndButton', EndButton);
  comp.registerComponent('SeekPrevButton', SeekPrevButton);
  comp.registerComponent('SeekNextButton', SeekNextButton);
  comp.registerComponent('RewindButton', RewindButton);

  return {
    CustomSeek,
    StartButton,
    RewindButton,
    SeekPrevButton,
    SeekNextButton,
    EndButton,
    CustomWrapper,
  };
}
